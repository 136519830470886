@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/common/lib/banner/Banner.less';
@import (reference) '../../../layouts/header/Header.less';

@top-margin-sm: -@header-height-sm;
@top-margin-lg: -@header-height-lg;
@hero-banner-large-lg: 700;
@hero-banner-large-xl: 700;
@hero-cls: ~'@{ra-prefix}-hero';
@hero-carousel-cls: ~'@{ra-prefix}-hero-carousel';
@hero-carousel-fade-cls: ~'@{hero-carousel-cls}-fade';
@hero-content-cls: ~'@{hero-cls}-content';
@hero-content-bottom-cls: ~'@{hero-content-cls}-bottom';
@hero-text-cls: ~'@{hero-cls}-text';
@hero-btn-cls: ~'@{hero-cls}-btn';
@hero-black-btn-cls: ~'@{hero-btn-cls}-black';
@hero-center-cls: ~'@{hero-cls}-center';
@hero-right-cls: ~'@{hero-cls}-right';
@hero-paragraph-cls: ~'@{hero-cls}-paragraph';

:export {
  heroCls: @hero-cls;
  heroCenterCls: @hero-center-cls;
  heroRightCls: @hero-right-cls;
  heroCarouselCls: @hero-carousel-cls;
  heroCarouselFadeCls: @hero-carousel-fade-cls;
  heroContentCls: @hero-content-cls;
  heroContentBottomCls: @hero-content-bottom-cls;
  heroTextCls: @hero-text-cls;
  heroParagraphCls: @hero-paragraph-cls;
  heroBtnCls: @hero-btn-cls;
  heroBlackBtnCls: @hero-black-btn-cls;
  fallbackColor: @ra-hero-fallback-background;
  heroHeightSmall: ~'@{banner-large-xs}px';
  heroHeightMedium: ~'@{banner-large-sm}px';
  heroHeightLarge: ~'@{hero-banner-large-lg}px';
  topMarginSmall: @top-margin-sm;
  topMarginLarge: @top-margin-lg;
}

.@{hero-carousel-cls} {
  margin-top: @top-margin-sm;

  @media only screen and (min-width: @ra-screen-lg) {
    margin-top: @top-margin-lg;
  }

  // There is a known bug in react-slick with swipe to fade action.
  // This is the solution until the bug is fixed in next releases of react-slick
  &.@{hero-carousel-fade-cls} .slick-track {
    transform: none !important;
  }

  .slick-active {
    z-index: @ra-zindex-hero-slick-active;
  }

  @media only screen and (min-width: @ra-screen-lg) {
    .slick-dots.slick-dots-bottom {
      padding: 10px 15px;
    }
  }
}

.@{hero-cls} {
  &.@{banner-size-large-cls} {
    .bannerHeight(@banner-large-xs, @banner-large-sm, @banner-large-md, @hero-banner-large-lg, @hero-banner-large-xl);
  }

  .@{hero-text-cls} {
    @media only screen and (min-width: @ra-screen-lg) {
      padding-left: 50px;
    }
  }

  .@{hero-content-bottom-cls} {
    color: @ra-color-white;
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;

    @media only screen and (min-width: @ra-screen-lg) {
      bottom: 13px;
      text-align: left;
    }
  }

  .@{hero-content-cls} {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: @ra-color-white;

    @media only screen and (min-width: @ra-screen-lg) {
      text-align: left;
    }

    .@{hero-text-cls} {
      @media only screen and (min-width: @ra-screen-lg) {
        width: 45%;
        min-width: 550px;
        max-width: 650px;
      }
    }

    .ra-heading,
    .@{hero-paragraph-cls} {
      margin-top: 25px;

      &:first-child {
        margin-top: 0;
      }
    }

    .@{hero-btn-cls}.ra-btn {
      background-color: @ra-color-white;
      border-color: @ra-color-white;
      color: @ra-color-black;

      &:hover,
      &:focus {
        background-color: @ra-color-black;
        border-color: @ra-color-black;
        color: @ra-color-white;
      }

      &.@{hero-black-btn-cls} {
        background-color: @ra-color-black;
        border-color: @ra-color-black;
        color: @ra-color-white;

        &:hover,
        &:focus {
          background-color: @ra-color-white;
          border-color: @ra-color-white;
          color: @ra-color-black;
        }
      }
    }

    .@{hero-paragraph-cls} {
      font-size: 16px;
      letter-spacing: 1px;
    }
  }

  &.@{hero-center-cls} {
    .@{hero-text-cls} {
      @media only screen and (min-width: @ra-screen-lg) {
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  &.@{hero-right-cls} {
    .@{hero-text-cls} {
      @media only screen and (min-width: @ra-screen-lg) {
        text-align: right;
        margin: 0 50px 0 auto;
      }
    }
  }
}
